
module.exports = {
  // 注册界面显示“注册”按钮
  INDEX_SHOW_REGISTER: true,
  // 注册界面显示“EAM APP”按钮
  INDEX_SHOW_EAM_APP: true,
  // 注册界面显示“注册”按钮
  INDEX_SHOW_MES_APP: true,
  // 注册界面显示“注册”按钮
  INDEX_SHOW_WMS_APP: true,
  // 登录界面显示“©2017 上海联物信息科技有限公司 版权所有”
  LOGIN_SHOW_LEANWO_TEXT: false,
  // 服务器地址
  serverUrl: '""',
  // 微信绑定时后台
  weixinServerUrl: '""',
  // 文件服务器地址
  fileServerUrl: '""',
  //中间件服务器地址
  middlewareUrl: '""',
}