'use strict';
import "@babel/polyfill";

var Vue = require('vue').default;
var VueRouter = require('vue-router').default;

var App = require('../src/App.vue').default;
var DicRoutes = require('./routes/register_routes.js')

window.$ = jQuery;
window.jQuery = jQuery;
window.jquery = jQuery;

// require('../static/css/common.css');

Vue.use(VueRouter);

const router = new VueRouter({
	routes: DicRoutes.routes
});

const app = new Vue({
	el: '#app',
	render: h => h(App),
  	router
})

