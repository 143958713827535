<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
var Common = require("./base/common/Common.js");
module.exports = {

  /*  watch:{
    	'$route.path': function (value) {
    		var _self = this;
    		
    		if(value == "/client-init" || value == "/login"){
    			return;
    		}
			$.ajax({
				url: Common.getApiURL("MenuResource/roleMenuAvailable"),
				type: 'get',
				dataType: 'json',
				contentType: "application/json",
				data: {
					routePath : value 
				},
				beforeSend: function(request) {
					Common.addTokenToRequest(request);
				},
				success: function(data) {
					if(data != undefined && data == false){
						window.location = Common.getRedirectUrl("application.html?#/notFound");
					}
				},
				error: function(XMLHttpRequest, textStatus, errorThrown) {
					Common.processException(XMLHttpRequest, textStatus, errorThrown);
				}
			});
		}
    }*/
}
</script>


<style>

  /** 修复Bootstrap样式的BUG，input-group form-control 控件 会显示在 日期控件的前面 */
  .input-group .form-control{
    /* z-index: 0; */
    /*checked by DengXin，修复input-group form-control 控件 会显示在 日期控件的前面的BUG*/
     position: static;
  }
</style>