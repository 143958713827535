var ModalFix = require("./ModalFix.js");

module.exports = {
    /**
     * 显示模态框
     */
    show : function(options){
        var dialog = BootstrapDialog.show(options);

        dialog.getModal().on('hidden.bs.modal', {dialog: this}, function (event) {
            ModalFix.fix();
        });
    },

	/**
	 * 显示通知
	 * @param {title} 标题
	 * @param {content} 内容
	 * @param {autoClose} 自动延时关闭时间(毫秒)
	 * author: 杨志杰
	 * version: 1.0
	 */
	notice : function(title, content, autoClose){
        var dialog = BootstrapDialog.show({
            title: title,
            message: content,
            type: BootstrapDialog.TYPE_WARNING,
        });

        dialog.getModal().on('hidden.bs.modal', {dialog: this}, function (event) {
            ModalFix.fix();
        });

        if(autoClose && autoClose > 0){
            setTimeout(function(){
                dialog.close();
            }, autoClose);
        }
    },

	/**
	 * 显示通知
	 * @param {title} 标题
	 * @param {content} 内容
	 * author: 杨志杰
	 * version: 1.0
	 */
	noticeBig : function(title, content){
        var dialog = BootstrapDialog.show({
            title: title,
            message: content,
            type: BootstrapDialog.TYPE_WARNING,
    		size: BootstrapDialog.SIZE_WIDE,
        });

        dialog.getModal().on('hidden.bs.modal', {dialog: this}, function (event) {
            ModalFix.fix();
        });
    },


    /**
	 * 显示消息
	 * @param {title} 标题
	 * @param {content} 内容
	 * @param {autoClose} 自动延时关闭时间(毫秒)
	 * author: 杨志杰
	 * version: 1.0
	 */
    info : function(title, content, autoClose){
        var dialog = BootstrapDialog.show({
            title: title,
            message: content,
            type: BootstrapDialog.TYPE_INFO,
        });

        dialog.getModal().on('hidden.bs.modal', {dialog: this}, function (event) {
            ModalFix.fix();
        });

        
        if(autoClose && autoClose > 0){
            setTimeout(function(){
                dialog.close();
            }, autoClose);
        }
    },


    /**
	 * 显示消息
	 * @param {title} 标题
	 * @param {content} 内容
	 * @param {autoClose} 自动延时关闭时间(毫秒)
	 * author: 杨志杰
	 * version: 1.0
	 */
    infoBig : function(title, content, autoClose){
        var dialog = BootstrapDialog.show({
            title: title,
            message: content,
            type: BootstrapDialog.TYPE_INFO,
    		size: BootstrapDialog.SIZE_WIDE,
        });

        dialog.getModal().on('hidden.bs.modal', {dialog: this}, function (event) {
            ModalFix.fix();
        });

        
        if(autoClose && autoClose > 0){
            setTimeout(function(){
                dialog.close();
            }, autoClose);
        }
    },

    /**
	 * 显示成功信息
	 * @param {title} 标题
	 * @param {content} 内容
	 * @param {autoClose} 自动延时关闭时间(毫秒)
	 * author: 杨志杰
	 * version: 1.0
	 */
    success : function(title, content, autoClose){
        var dialog = BootstrapDialog.show({
            title: title,
            message: content,
            type: BootstrapDialog.TYPE_SUCCESS,
        });

        dialog.getModal().on('hidden.bs.modal', {dialog: this}, function (event) {
            ModalFix.fix();
        });

        
        if(autoClose && autoClose > 0){
            setTimeout(function(){
                dialog.close();
            }, autoClose);
        }
    },

    /**
     * 显示错误信息
     * @param {title} 标题
     * @param {content} 内容
     * @param {autoClose} 自动延时关闭时间(毫秒)
     * author: 杨志杰
     * version: 1.0
     */
    error : function(title, content, autoClose){
        var dialog = BootstrapDialog.show({
            title: title,
            message: content,
            type: BootstrapDialog.TYPE_DANGER,
        });

        dialog.getModal().on('hidden.bs.modal', {dialog: this}, function (event) {
            ModalFix.fix();
        });
    }
    
}