var Notify = require("../widget/Notify.js");
var CustomerEnvironment = require("../../../config/CustomerEnvironment.js");


module.exports = {
  pageSize: 20,


  // 异常处理
  processException: function (XMLHttpRequest, textStatus, errorThrown) {
    var _self = this;
    console.log(XMLHttpRequest);
    if (XMLHttpRequest.status == 400) {
      // 400 Bad Request
      Notify.error("400", XMLHttpRequest.responseText, true);
    } else if (XMLHttpRequest.status == 401) {
      var currentUrl = window.location;
      // 系统未登录
      if (window.location.href.indexOf('redirectUrl=') < 0) {
        window.location = _self.getRedirectUrl("application.html?#/login?redirectUrl=" + currentUrl);
      }
    } else if (XMLHttpRequest.status == 500) {
      // 500 Internal Server Error
      Notify.error("500", XMLHttpRequest.responseText, true);
      if (XMLHttpRequest.responseText.indexOf("登录超时") > 0) {
        // 如果异常信息包含“登录超时”，则2秒后跳转到登录页面
        setTimeout(function () {
          window.location = _self.getRedirectUrl("application.html?#/login");
        }, 2 * 1000);
      }
    } else {
      Notify.error("服务器异常", XMLHttpRequest.responseText, true);
    }
  },


  /**
   * 获取主机地址
   */
  getRootPath: function () {
    //获取当前网址，如： http://localhost:8083/myproj/view/my.jsp
    var curWwwPath = window.document.location.href;
    //获取主机地址之后的目录，如： myproj/view/my.jsp
    var pathName = window.document.location.pathname;
    var pos = curWwwPath.indexOf(pathName);
    //获取主机地址，如： http://localhost:8083
    var localhostPaht = curWwwPath.substring(0, pos);
    return localhostPaht;
  },

  getHostPageBaseURL: function () {
    return this.getRootPath() + "/"
  },

  // 获取图片路径url
  getFileServerUrl: function () {
    return this.getRootPath() + "/"
  },

  // 获取API的地址
  getApiURL: function (apiName) {
    return this.getHostPageBaseURL() + "api/" + apiName;
  },

  // 获取中间件API的地址
  getMidURL: function (apiName) {
    var that = this;
    if (window.middlewareUrl == undefined) {
      $.ajax({
        url: that.getApiURL("MiddleareResource/getApiUrl"),
        type: "GET",
        dataType: "text",
        async: false,
        beforeSend: function (request) {
          that.addTokenToRequest(request);
        },
        success: function (data) {
          if (data.indexOf('http') == 0) {
            window.middlewareUrl = data;
          } else {
            window.middlewareUrl = that.getHostPageBaseURL() + "mid";
          }
          if (window.middlewareUrl.indexOf(window.middlewareUrl.length() - 1) == "/") {
            window.middlewareUrl = window.middlewareUrl.substring(0, window.middlewareUrl.length() - 2)
          }
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {
          window.middlewareUrl = that.getHostPageBaseURL() + "mid";
        }
      });
    }
    if (apiName.indexOf(0) == "/") {
      apiName = apiName.substring(1, apiName.length() - 2);
    }
    return window.middlewareUrl + apiName;
  },

  // 获取微信测试api地址
  getWeixinApiURL: function (apiName) {
    return that.getHostPageBaseURL() + "/api/" + apiName;
  },

  // 获取图片路径
  getImageUrl: function (imageName) {
    if (imageName == null || imageName == '') {
      return this.getFileServerUrl() + 'notFound.png';
    } else {
      return this.getFileServerUrl() + imageName;
    }
  },

  // 获取图片路径
  getImageSrc: function (className, imageName) {
    var accountId = localStorage.getItem("account");   
    if (imageName == null) {
      return null;
    }
    if (imageName != null && imageName[0] == '/') {
      return this.getFileServerUrl() + "Files/" + accountId + "/Images/" + className + imageName;
    } else {
      return this.getFileServerUrl() + "Files/" + accountId + "/Images/" + className + "/" + imageName;
    }
  },


  // 获取略缩图图片路径
  getThumbnailImageSrc: function (className, imageName) {
    var accountId = localStorage.getItem("account");
    if (imageName == null) {
      return null;
    }
    if (imageName != null && imageName[0] == '/') {
      return this.getFileServerUrl() + "Files/" + accountId + "/Images/" + className + "/thumbnail" + imageName;
    } else {
      return this.getFileServerUrl() + "Files/" + accountId + "/Images/" + className + "/thumbnail/" + imageName;
    }
  },

  /**
   * 获取附件的路径
   * @param  {[type]} className [description]
   * @param  {[type]} imageName [description]
   * @return {[type]}           [description]
   */
  getAttachmentsSrc: function (className, imageName) {
    var accountId = localStorage.getItem("account");
    return this.getFileServerUrl() + "Files/" + accountId + "/Attachments/" + className + "/" + imageName;
  },

  // 获取图片路径
  getVideoSrc: function (className, imageName) {
    var accountId = localStorage.getItem("account");
    if (imageName == undefined || imageName == "") {
      return this.getHostPageBaseURL() + "static/image/noImage.jpg";
    }

    return this.getFileServerUrl() + "Files/" + accountId + "/Video/" + className + "/" + imageName;
  },


  //获取资源路径   type： 图片image,视频video,文件file,
  getResourceUrl: function (type, className, resourceName) {
    var accountId = localStorage.getItem("account");
    if (resourceName == undefined || className == undefined || type == undefined || resourceName == '' || className == '' || type == '') {
      return;
    }
    if (type == "image") {
      return this.getFileServerUrl() + "Files/" + accountId + "/Images/" + className + "/" + resourceName;
    }
    if (type == "video") {
      return this.getFileServerUrl() + "Files/" + accountId + "/Video/" + className + "/" + resourceName;
    }
    if (type == "file") {
      return this.getFileServerUrl() + "Files/" + accountId + "/Files/" + className + "/" + resourceName;
    }

  },

  getApsURL: function (apiName) {
    var apsBaseUrl = localStorage.getItem("apsBaseUrl");
    if (apsBaseUrl == undefined) {
      Notify.error("错误", "系统参数\"apsBaseUrl\"未设置,请联系系统管理员设置参数", false);
      return;
    }
    return apsBaseUrl + apiName;
  },

  getActivitiURL: function (apiName) {
    var apsBaseUrl = localStorage.getItem("activitiUrl");
    if (apsBaseUrl == undefined) {
      Notify.error("错误", "系统参数\"activitiUrl\"未设置,请联系系统管理员设置参数", false);
      return;
    }
    return apsBaseUrl + apiName;
  },

  getSchedulingURL: function (apiName) {
    var _self = this;
    var apsBaseUrl = localStorage.getItem("schedulingUrl");
    if (apsBaseUrl == undefined) {
      _self.loadSystemParam("schedulingUrl", function () {
        apsBaseUrl = localStorage.getItem("schedulingUrl");
        if (apsBaseUrl == undefined) {
          Notify.error("错误", "系统参数\"schedulingUrl\"未设置,请联系系统管理员设置参数", false);
          return;
        }
      })
    }
    return apsBaseUrl + apiName;
  },

  getCameraURL: function (apiName) {
    var apsBaseUrl = localStorage.getItem("cameraBaseURL");
    if (apsBaseUrl == undefined) {
      Notify.error("错误", "系统参数\"cameraBaseURL\"未设置,请联系系统管理员设置参数", false);
      return;
    }
    return apsBaseUrl + apiName;
  },

  //设置路径到localStorage
  setHref: function () {
    var href = window.location.href;
    if (href.indexOf("http") == 0) {
      href = href.substring(0, href.indexOf('#') + 2);
      localStorage.setItem("href", href);
    } else {
      var hostPageBaseURL = localStorage.getItem("hostPageBaseURL");
      if (hostPageBaseURL == undefined) {
        var href = window.location.href;
        href = href.substring(0, href.indexOf('#') + 2);
        localStorage.setItem("href", href);
      }
    }
  },

  //加载系统参数到localStorage
  loadSystemParam: function (systemParamName, success) {
    var that = this;
    $.ajax({
      url: that.getApiURL("SystemParamResource/loadSystemParam"),
      type: "GET",
      dataType: "text",
      data: {
        "systemParamName": systemParamName
      },
      beforeSend: function (request) {
        that.addTokenToRequest(request);
      },
      success: function (data) {
        localStorage.setItem(systemParamName, data);
        success();
      },
      error: function (XMLHttpRequest, textStatus, errorThrown) {
        that.processException(XMLHttpRequest, textStatus, errorThrown);
      }
    });
  },

  // 给请求头中加上account和token信息
  addTokenToRequest: function (request) {
    var token = $.cookie('token');
    var account = $.cookie('account');
    if (token == undefined) {
      var localStorageToken = localStorage.getItem('token');
      if (localStorageToken != undefined) {
        token = localStorageToken;
      }
    }
    if (account == undefined) {
      var localStorageAccount = localStorage.getItem('account');
      if (localStorageAccount != undefined) {
        account = localStorageAccount;
      }
    }
    request.setRequestHeader("account", account);
    request.setRequestHeader("token", token);

  },

  /**
   * 获取Token
   */
  getToken: function () {
    return $.cookie('token');
  },

  // 获取新建对象的Id
  getNewRecordId: function () {
    window.CRUDId++;
    return window.CRUDId;
  },

  // 清空 Cookie
  clearCookie: function () {
    var keys = document.cookie.match(/[^ =;]+(?=\=)/g);
    if (keys) {
      for (var i = keys.length; i--;) {
        // 清除当前域名路径的有限日期
        document.cookie = keys[i] + '=0;path=/;expires=' + new Date(0).toUTCString();
        // Domain Name域名 清除当前域名的
        document.cookie = keys[i] + '=0;path=/;domain=' + document.domain + ';expires=' + new Date(0).toUTCString();
        // 清除一级域名下的或指定的
        document.cookie = keys[i] + '=0;path=/;domain=baidu.com;expires=' + new Date(0).toUTCString();
      }
    }
  },

  clearLocalStorage: function () {
    // 清理localStorage时需要保留的参数列表
    var reserveParams = ["hostPageBaseURL", "workShopId", "resourceInstanceId",
      "resourceInstanceName", "apsBaseUrl", "cameraBaseURL"];
    //存放的信息
    var reserveParamValues = [];

    //获取参数信息 
    var len = reserveParams.length;
    for (var i = 0; i < len; i++) {
      var reserveParam = reserveParams[i];
      var reserveParamValue = "";
      if (localStorage.getItem(reserveParam) != undefined) {
        reserveParamValue = localStorage.getItem(reserveParam);
      }
      reserveParamValues.push(reserveParamValue);
    }

    //清理localStorage
    window.localStorage.clear();

    //还原参数信息
    for (var i = 0; i < len; i++) {
      localStorage.setItem(reserveParams[i], reserveParamValues[i]);
    }

  },

  showDialog: function (title, content, type) {
    if (type == "success") {
      Notify.success(title, content, 4000);
    }
    else if (type == "error") {
      Notify.error(title, content, -1);
    }
    else if (type == "info") {
      Notify.info(title, content, 2000);
    }
    else if (type == "notice") {
      Notify.notice(title, content, 2000);
    }
  },

  //合并路由数组
  mergeArray: function (arr1, arr2) {
    var arr = [];
    for (var k = 0, len = arr1.length; k < len; k++) {
      arr.push(arr1[k]);
    }
    for (var i = 0, len2 = arr2.length; i < len2; i++) {
      //是否添加该元素
      var flag = true;
      for (var j = 0, len1 = arr.length; j < len1; j++) {
        //如果path相同，则合并children；如果children不存在，则不合并；
        if (arr[j].path == arr2[i].path) {
          flag = false;
          if (arr[j].children instanceof Array && arr2[i].children instanceof Array) {
            arr[j].children = this.mergeArray(arr[j].children, arr2[i].children);
          }
        }
      }
      if (flag) {
        arr.push(arr2[i]);
      }
    }
    //将path为"*"的项移到最后
    var temp;
    var index;
    for (var n = 0, len3 = arr.length; n < len3; n++) {
      if (arr[n].path == "*" || arr[n].path == "/*") {
        index = n;
      }
    }
    if (index != undefined) {
      temp = arr[index];
      arr[index] = arr[len3 - 1];
      arr[len3 - 1] = temp;
    }
    return arr;
  },

  // 获取路由中的参数
  getRouteParam: function (name) {
    var reg = new RegExp("(^|\\?|&)" + name + "=([^&]*)(\\s|&|$)", "i");
    if (reg.test(location.href)) return unescape(RegExp.$2.replace(/\+/g, " "));
    return "";
  },

  /**
   * 获取跳转的路径
   * @param {*} url 
   */
  getRedirectUrl: function (url) {
    var href = window.location.href;
    if (href.indexOf("pcapp") >= 0) {
      return "/pcapp/" + url;
    } else {
      return url;
    }
  },

  /**
   * 可以修改url的参数
   * 例如将
   *    www.baidu.com
   * 修改为
   *    www.baidu.com?name=123
   * 操作为
   *    window.location.href = changeURLArg(window.location.href,'name',123)
   */
  changeURLArg: function (url, arg, value) {
    var pattern = arg + '=([^&]*)';
    var replaceText = arg + '=' + value;
    if (url.match(pattern)) {
      var tmp = '/(' + arg + '=)([^&]*)/gi';
      tmp = url.replace(eval(tmp), replaceText);
      return tmp;
    } else {
      if (url.match('[\?]')) {
        return url + '&' + replaceText;
      } else {
        return url + '?' + replaceText;
      }
    }
  }

}

